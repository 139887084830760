import React, { useMemo, useState } from 'react';
import { Box, makeStyles, Theme, Tooltip } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import OpenInNew from '@material-ui/icons/OpenInNew';

import { Typography, Button } from '@clef/client-library';
import { LabelType } from '@clef/shared/types';

import { defaultSelectOptions } from '@/constants/data_browser';
import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { RegisteredModelWithThreshold } from '@/api/model_api';
import { useGetModelMetricsQuery } from '@/serverStore/modelAnalysis';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDatasetMediaCountQuery } from '@/serverStore/dataset';

import ModelPerformance from '../ModelPerformance';
import { getEvaluationSetName } from '../utils';
import ModelComparisonTrainingSettings from './ModelComparisonTrainingSettings';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles<Theme, { comparisonValue: number }>(theme => ({
  dialogSection: {
    display: 'flex',
    padding: theme.spacing(5, 0, 5, 0),
  },
  compareSummary: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(10),
    height: 190,
    borderRadius: 20,
    border: `1px solid ${theme.palette.greyModern[300]}`,
    backgroundColor: theme.palette.greyModern[50],
  },
  compareInfoHeaderColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  compareInfoHeaderColumnTitle: {
    marginBottom: theme.spacing(1),
  },
  openInNew: {
    marginLeft: theme.spacing(1),
    color: theme.palette.greyModern[600],
    fontSize: 20,
    cursor: 'pointer',
  },
  modelInfoTitle: {
    color: theme.palette.greyModern[500],
  },
  performanceTrend: {
    color: props =>
      props.comparisonValue > 0
        ? theme.palette.green[500]
        : props.comparisonValue < 0
        ? theme.palette.orange[500]
        : theme.palette.greyModern[500],
  },
  modelInfo: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

interface IProps {
  evaluationSet: EvaluationSetItem;
  baseline: RegisteredModelWithThreshold;
  candidate: RegisteredModelWithThreshold;
}

export const ModelComparisonSummary: React.FC<IProps> = ({
  evaluationSet,
  baseline,
  candidate,
}) => {
  const { data: baselineModelMetrics } = useGetModelMetricsQuery(
    baseline.id,
    evaluationSet.id,
    baseline.threshold,
  );
  const { data: candidateModelMetrics } = useGetModelMetricsQuery(
    candidate.id,
    evaluationSet.id,
    candidate.threshold,
  );

  const { data: mediaCount } = useDatasetMediaCountQuery({
    version: evaluationSet.datasetVersion.version,
    selectOptions: defaultSelectOptions,
  });

  const baselineOverallPerformance = baselineModelMetrics?.metrics?.all?.performance;
  const candidateOverallPerformance = candidateModelMetrics?.metrics?.all?.performance;
  const comparisonValue = useMemo(() => {
    if (!baselineOverallPerformance || !candidateOverallPerformance) {
      return null;
    }
    return (candidateOverallPerformance - baselineOverallPerformance) * 100;
  }, [baselineOverallPerformance, candidateOverallPerformance]);

  const styles = useStyles({ comparisonValue: comparisonValue ?? 0 });

  const handleOpenModelPage = (model: RegisteredModelWithThreshold) => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set('modelId', model.id);
    searchParams.set('threshold', model.threshold.toString());
    url.search = searchParams.toString();
    window.open(url, '_blank');
  };

  const [openTrainingSettings, setOpenTrainingSettings] = useState<boolean>(false);
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  return (
    <Box className={styles.dialogSection} flexDirection="row" justifyContent="space-between">
      <Box className={styles.compareSummary}>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          <ModelPerformance
            performance={baselineOverallPerformance}
            performanceLabel="Baseline"
            showImagesAndInstancesNumber={false}
            evaluationSet={evaluationSet}
          />
          <Box className={styles.compareInfoHeaderColumn}>
            <Box className={styles.compareInfoHeaderColumnTitle}>
              <Typography variant="body_bold">{t('Baseline model')}</Typography>
            </Box>
            <Box className={styles.modelInfo}>
              <Typography maxWidth={300}>{baseline.modelName}</Typography>
              {labelType !== LabelType.Classification && (
                <Typography
                  className={styles.modelInfoTitle}
                >{`(${baseline.threshold})`}</Typography>
              )}
              <OpenInNew
                className={styles.openInNew}
                onClick={() => handleOpenModelPage(baseline)}
              />
            </Box>
          </Box>
        </Box>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          <ModelPerformance
            performance={candidateOverallPerformance}
            performanceLabel="Candidate"
            showImagesAndInstancesNumber={false}
            evaluationSet={evaluationSet}
          />
          <Box className={styles.compareInfoHeaderColumn}>
            <Box className={styles.compareInfoHeaderColumnTitle}>
              <Typography variant="body_bold">{t('Candidate model')}</Typography>
            </Box>
            <Box className={styles.modelInfo}>
              <Typography maxWidth={300}>{candidate.modelName}</Typography>
              {labelType !== LabelType.Classification && (
                <Typography
                  className={styles.modelInfoTitle}
                >{`(${candidate.threshold})`}</Typography>
              )}
              <OpenInNew
                className={styles.openInNew}
                onClick={() => handleOpenModelPage(candidate)}
              />
            </Box>
          </Box>
        </Box>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          {comparisonValue !== null ? (
            <Box display="flex" alignItems="center" mt={2}>
              <Typography variant="h2" className={styles.performanceTrend}>
                {Math.abs(comparisonValue).toFixed(1)}%
              </Typography>
              {comparisonValue > 0 && <ArrowUpwardIcon className={styles.performanceTrend} />}
              {comparisonValue < 0 && <ArrowDownwardIcon className={styles.performanceTrend} />}
              <Tooltip
                placement="top"
                arrow
                interactive
                title={t(
                  'The difference in score between the candidate model and the baseline model. Calculated as: candidate - baseline.',
                )}
              >
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </Box>
          ) : (
            <Box />
          )}
          <Box className={styles.compareInfoHeaderColumn}>
            <Box className={styles.compareInfoHeaderColumnTitle}>
              <Typography variant="body_bold">{t('Evaluation set')}</Typography>
            </Box>
            <Box className={styles.modelInfo}>
              <Typography maxWidth={300}>{getEvaluationSetName(evaluationSet)}</Typography>
              <Typography className={styles.modelInfoTitle}>{`(${mediaCount} images)`}</Typography>
              <OpenInNew
                className={styles.openInNew}
                onClick={() => {
                  const newUrl =
                    window.location.href.split('/models')[0] +
                    '/data/dataset_snapshot/' +
                    evaluationSet.datasetVersion.version;
                  window.open(newUrl, '_blank', 'noopener,noreferrer');
                }}
              />
            </Box>
          </Box>
        </Box>
        <Button
          id="compare-training-settings-btn"
          variant="outlined"
          onClick={() => setOpenTrainingSettings(true)}
        >
          {t('Compare Training Settings')}
        </Button>
      </Box>

      {openTrainingSettings && (
        <ModelComparisonTrainingSettings
          open={openTrainingSettings}
          onClose={() => setOpenTrainingSettings(false)}
          baseline={baseline}
          candidate={candidate}
        />
      )}
    </Box>
  );
};
