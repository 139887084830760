import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Konva from 'konva';
import { Stage, Layer, Image, useStrictMode } from 'react-konva';
import {
  LineAnnotation,
  TextAnnotation,
  BitMapAnnotation,
  PureCanvasAnnotation,
  Dimensions,
  Position,
  BoxAnnotation,
} from '@clef/shared/types';
import useImage from 'use-image';
import { Skeleton } from '@material-ui/lab';

import {
  BoxAnnotationComponent,
  BoxSuggestionAnnotationComponent,
  LineAnnotationComponent,
  TextAnnotationComponent,
  BitMapAnnotationComponent,
  PureCanvasAnnotationComponent,
} from './components/Shapes';
import ZoomFloater from './components/ZoomFloater';

import useWindowEventListener from '../../hooks/useWindowEventListener';
import { useMediaInteractiveCanvasStyles } from './styles';
import {
  AccurateLabelingZoomScaleThreshold,
  calcAccurateOffset,
  calcZoomScaleRange,
  extractColorFromHiddenLayer,
  FitMediaPaddingBottom,
  FitMediaPaddingTop,
  MaxScrollZoomRatio,
  MinScrollZoomRatio,
  ScrollDeltaYCap,
  ZoomScale,
  getStagePositionAfterZoom,
  calculateDraggableBoundary,
} from './utils';
import HideLabelsButtonFloater from './components/HideLabelsButtonFloater';
import { useKeyPressHold } from '../../hooks/useKeyPress';
import useKeyPress from '../../hooks/useKeyPress';
import { useClickOutside } from '../../hooks/useClickOutside';
import {
  CanvasMode,
  CanvasAnnotationType,
  CanvasAnnotation,
  CanvasInteractionEvent,
  MediaInteractiveCanvasProps,
  AnnotationChangeType,
  SetHoveredAnnotationParams,
  AnnotationSourceType,
} from './types';
import { useAnnotationCreator } from './creators';
import { cap } from '@clef/shared/utils';
import { CursorPolygonMinus, CursorPolygonPlus } from './components/cursors';
import { isMacOS } from '../../utils/userAgent';
import useMemento from '../../hooks/useMemento';
import { KonvaEventObject } from 'konva/types/Node';
import { isEmpty, isEqual } from 'lodash';
import { Stage as StageType } from 'konva/types/Stage';
import { usePrevious, useThrottle } from '../..';
import Crosshair from './components/Crosshair';
import { DefectCreator, DefectCreatorRef } from './components/DefectCreator';
import { useDefectCreator } from './hooks/useDefectCreator';
import classNames from 'classnames';

// TODO: after upgrading Konva, this config will be renamed to `pointerEventsEnabled` and has default value `true`.
// Remove this line after upgrade.
(Konva as any)._pointerEventsEnabled = true;

// to prevent segmentation labeling offset when the browser is zoomed
// https://stackoverflow.com/questions/55272767/react-konva-disable-canvas-element-devicepixelratio-scale
Konva.pixelRatio = Math.round(window.devicePixelRatio);

type MediaInteractiveCanvas = {
  undo: () => void;
  redo: () => void;
  setZoomScale: (
    newZoomScale: ZoomScale,
    mousePosition?: Position | null,
    skipCallback?: boolean,
  ) => void;
  setAnnotations: (
    newAnnotations: CanvasAnnotation[] | ((prev: CanvasAnnotation[]) => CanvasAnnotation[]),
    changeType: AnnotationChangeType,
    // avoid infinite loop when sync'ing two canvas
    skipCallback?: boolean,
  ) => void;
  setSelectedAnnotation: (annotationId: string) => void;
  setHoveredAnnotation: (data?: SetHoveredAnnotationParams) => void;
  setStagePosition: (position: Position) => void;
  resizeScale: () => void;
  mouseMove: (e?: KonvaEventObject<MouseEvent>, skipCallback?: boolean) => void;
  changeColor: (from: string, to: string) => void;
  defectCreator: DefectCreatorRef | null;
  pressEnter: (newClassColor?: string) => void;
  clearWipAnnotations?: () => void;
};

// eslint-disable-next-line no-redeclare
const MediaInteractiveCanvas = forwardRef<MediaInteractiveCanvas, MediaInteractiveCanvasProps>(
  (props, ref) => {
    const {
      imageSrc = '',
      aspectRadio,
      mode = CanvasMode.View,
      annotations: annotationsProps,
      enableHideLabels = false,
      showGroundTruthLabels: showLabelsProps = true,
      builtInZoom = false,
      onImageLoad,
      onAnnotationChanged,
      shapeProps = {},
      onInteractionEvent,
      onZoomScaleChange,
      onFitZoomScaleChange,
      onDragMove,
      onMouseMove: onMouseMoveProps,
      onMouseClick: onMouseClickProps,
      freeDrag,
      enablePinchScrollZoom,
      enableFitPadding,
      properties,
      enablePixelation,
      enableCrosshair,
      enableGrayscaleImage,
      pendingDefectColor,
      onDefectCreate,
      onDisabledDrawingMouseDown,
      onDefectCreatorModeChange,
      pendingDefectName,
      defectCreatorTips,
      className,
      enableContour = false,
      disableUndoRedo = false,
      hidePredictions = false,
      enableDefectCreatorClickAwayEvent = false,
      fitMediaPaddingTop = FitMediaPaddingTop,
      fitMediaPaddingBottom = FitMediaPaddingBottom,
      samAnnotations,
      onCreatorError,
      onnxModel,
      imageEmbedding,
    } = props;
    useStrictMode(true);
    const enableDefectCreator =
      !!onDefectCreate && !!pendingDefectColor && ![CanvasMode.View, CanvasMode.Pan].includes(mode);

    const {
      current: annotations,
      replace: replaceAnnotations,
      set: setAnnotations,
      reset: resetAnnotations,
      undo,
      redo,
      canUndo,
      canRedo,
    } = useMemento(annotationsProps);

    const layerRef = useRef<Konva.Layer>(null);
    const predictionLayerRef = useRef<Konva.Layer>(null);

    const setSelectedAnnotation = useCallback(
      (annotationId: string) => {
        if (mode !== CanvasMode.IBox && mode !== CanvasMode.IText) {
          return;
        }
        replaceAnnotations(prev => {
          const newAnnotations = prev.map(ann => ({
            ...ann,
            selected: ann.id === annotationId,
          }));
          onAnnotationChanged?.(newAnnotations, AnnotationChangeType.Select, layerRef.current);
          return newAnnotations;
        });
      },
      [mode, onAnnotationChanged, replaceAnnotations],
    );

    const setSelectedSuggestionAnnotation = useCallback(
      (annotationId: string) => {
        // for suggestion, use setAnnotations in case of undo
        setAnnotations(prev => {
          const newAnnotations = prev.map(ann => ({
            ...ann,
            data: ann.id === annotationId ? { ...ann.data, dashed: false } : ann.data,
            selected: ann.id === annotationId,
            group: ann.id === annotationId ? AnnotationSourceType.GroundTruth : ann.group,
          }));
          onAnnotationChanged?.(newAnnotations, AnnotationChangeType.Edit, layerRef.current);
          return newAnnotations;
        });
      },
      [onAnnotationChanged, setAnnotations],
    );

    const onAnnotationCreated = useCallback(
      (ann: CanvasAnnotation) => {
        const newAnnotations = [
          ...(annotations ?? []),
          { ...ann, group: AnnotationSourceType.GroundTruth },
        ];
        setAnnotations(newAnnotations);
        onAnnotationChanged?.(newAnnotations, AnnotationChangeType.Create, layerRef.current);
      },
      [annotations, onAnnotationChanged, setAnnotations],
    );

    const updateAnnotation = useCallback(
      (newAnnotation: BoxAnnotation | TextAnnotation, annotationId: string) => {
        const newAnnotations = annotations.map(ann =>
          ann.id === annotationId ? { ...ann, data: { ...ann.data, ...newAnnotation } } : ann,
        );
        setAnnotations(newAnnotations);
        onAnnotationChanged?.(newAnnotations, AnnotationChangeType.Edit, layerRef.current);
      },
      [annotations, onAnnotationChanged, setAnnotations],
    );

    // for view-only mode, rely on the props instead of state.
    // one use case is for defect book edit mode: viewers do not need to sync annotations into this state after edit
    const isViewMode = mode === CanvasMode.View;
    const displayAnnotations = isViewMode
      ? annotationsProps.map(item => {
          const ann = annotations.find(an => an.id === item.id);
          return {
            ...item,
            hovered: ann?.hovered,
          };
        })
      : annotations;

    const displayAnnotationsWithPredictions = displayAnnotations?.filter(
      ann => ann.group === AnnotationSourceType.Prediction,
    );

    // This includes heatmap group annotations
    const displayAnnotationsWithGroundTruthAndHeatmap = displayAnnotations
      ?.filter(
        ann =>
          ann.group === AnnotationSourceType.GroundTruth ||
          ann.group === AnnotationSourceType.Heatmap,
      )
      .concat(samAnnotations ? samAnnotations : []);

    const displayAnnotationsWithSuggestion = displayAnnotations?.filter(
      ann => ann.group === AnnotationSourceType.Suggestion,
    );

    const imageRef = useRef<Konva.Image | null>(null);

    const mediaContainerRef = useRef<HTMLDivElement | null>(null);
    const { color, lineStrokeWidth = 2, segmentationOpacity = 1, text = '-text-' } = shapeProps;

    const { defectCreatorRef, loadDefectCreatorRef, ...defectCreatorHooks } = useDefectCreator({
      defectName: pendingDefectName,
      defectColor: color,
      imageRef,
      mediaContainerRef,
      enabled: enableDefectCreator,
    });

    useEffect(() => {
      onDefectCreatorModeChange?.(defectCreatorRef.current?.mode ?? null);
    }, [defectCreatorRef, defectCreatorRef.current?.mode, onDefectCreatorModeChange]);

    useKeyPress(
      'Delete,Backspace',
      isViewMode
        ? undefined
        : () => {
            const newAnnotations = annotations.filter(ann => !ann.selected);
            setAnnotations(newAnnotations);

            onAnnotationChanged?.(newAnnotations, AnnotationChangeType.Delete, layerRef.current);

            defectCreatorHooks.onAnnotationDelete();
          },
    );

    const styles = useMediaInteractiveCanvasStyles();
    const [nullableImage] = useImage(imageSrc ?? '', 'use-credentials');
    // `image` will never become null once set with a valid value,
    // so that image position will not change even when imageSrc is changed.
    // The only use case now is image enhancement feature.
    // It changes imageSrc and we need to keep image in the same position.
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    useEffect(() => {
      nullableImage && setImage(nullableImage);
    }, [nullableImage]);

    const once = useRef(false);

    useEffect(() => {
      if (image && !once.current) {
        once.current = true;
        onImageLoad?.(image);
      }
    }, [image, onImageLoad]);

    // there is no `width: 100% and height: 100%` for Stage, we need to use container width & height
    // to make stage responsive: https://konvajs.org/docs/sandbox/Responsive_Canvas.html
    const [mediaContainerSize, setMediaContainerSize] = useState<Dimensions>();

    const [zoomScale, setZoomScale] = useState<ZoomScale>('fit');
    // zoom scale for auto fit
    const [fitZoomScale, setFitZoomScale] = useState<number>(1);

    const zoomScaleNumber = zoomScale === 'fit' ? fitZoomScale : zoomScale;

    const stageRef = useRef<StageType>(null);

    const actualImageWidth = properties?.width || image?.width || 0;
    const actualImageHeight = properties?.height || image?.height || 0;

    const updateStagePositionAfterZoom = useCallback(
      (newZoomScale: number, mousePosition?: Position | null) => {
        if (newZoomScale === zoomScale) {
          return;
        }
        if (mediaContainerRef.current && image && stageRef.current) {
          const { clientWidth, clientHeight } = mediaContainerRef.current;
          const stage = stageRef.current.getStage();
          const zoomCenter = mousePosition ?? { x: clientWidth / 2, y: clientHeight / 2 };

          let positionAfterZoom = getStagePositionAfterZoom(
            stage.getPosition(),
            zoomCenter,
            zoomScaleNumber,
            newZoomScale,
          );

          // when not allowed for free drag, e.g. in defect book, cut the position by boundary
          if (!freeDrag) {
            positionAfterZoom = calculateDraggableBoundary(
              mediaContainerRef.current,
              actualImageWidth,
              actualImageHeight,
              newZoomScale,
              positionAfterZoom,
            );
          }
          stage.setPosition(positionAfterZoom);
        }
      },
      [actualImageHeight, actualImageWidth, freeDrag, image, zoomScale, zoomScaleNumber],
    );

    const fitStageToCenter = useCallback(
      (newFitZoomScale: number) => {
        if (mediaContainerRef.current && image && stageRef.current) {
          const { clientWidth, clientHeight } = mediaContainerRef.current;
          const paddingTop = enableFitPadding ? fitMediaPaddingTop : 0;
          stageRef.current?.getStage().setPosition({
            x: Math.max(0, (clientWidth - actualImageWidth * newFitZoomScale) / 2),
            y: Math.max(paddingTop, (clientHeight - actualImageHeight * newFitZoomScale) / 2),
          });
        }
      },
      [actualImageHeight, actualImageWidth, enableFitPadding, fitMediaPaddingTop, image],
    );

    const spaceKey = useKeyPressHold(' ');

    const isInteractiveMode = mode !== CanvasMode.View && mode !== CanvasMode.Pan;
    const isInteractive = !spaceKey && isInteractiveMode;
    const [mousePos, setMousePos] = useState<Position>();

    const resizeScale = useCallback(() => {
      if (mediaContainerRef.current && image) {
        const { clientWidth, clientHeight } = mediaContainerRef.current;
        setMediaContainerSize({ width: clientWidth, height: clientHeight });
        const paddingY =
          zoomScale === 'fit' && enableFitPadding ? fitMediaPaddingTop + fitMediaPaddingBottom : 0;
        const newFitZoomScale = Math.min(
          clientWidth / actualImageWidth,
          (clientHeight - paddingY) / actualImageHeight,
        );
        setFitZoomScale(newFitZoomScale);
        onFitZoomScaleChange?.(newFitZoomScale);
        if (zoomScale === 'fit') {
          fitStageToCenter(newFitZoomScale);
        }
      }
    }, [
      actualImageHeight,
      actualImageWidth,
      enableFitPadding,
      fitMediaPaddingBottom,
      fitMediaPaddingTop,
      fitStageToCenter,
      image,
      onFitZoomScaleChange,
      zoomScale,
    ]);

    useWindowEventListener('resize', resizeScale);

    const prevImage = usePrevious(image);
    useEffect(() => {
      if (image !== prevImage) {
        resizeScale();
      }
    }, [image, prevImage, resizeScale, zoomScale]);

    useEffect(() => {
      if (zoomScale === 'fit') {
        resizeScale();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableFitPadding]);

    //Monitor whether the mediaContainerRef is changing in case the final length and width are not taken
    useEffect(() => {
      resizeScale();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaContainerRef.current?.clientHeight, mediaContainerRef.current?.clientWidth]);

    // free drag mode ignores wether the image is overflow or not
    const isStageOverflow = zoomScale !== 'fit' && zoomScale > fitZoomScale;
    const draggable = (freeDrag || (!freeDrag && isStageOverflow)) && !isInteractive;

    // currently only for bounding boxes
    const [isHoverOnAnnotation, setIsHoverOnAnnotation] = useState(false);

    const finalColor = enableDefectCreator ? color || pendingDefectColor : color;

    const [hideLabels, setHideLabels] = useState(false);

    const {
      creatorHelperComponent,
      creatorPreviewComponent,
      creatorInteractiveProps,
      creatorCustomHandlers,
      customCursor,
      clearWipAnnotations,
    } = useAnnotationCreator(mode, {
      zoomScale: zoomScaleNumber,
      // If color is falsy, use the pending defect color for quick create defect flow
      color: finalColor,
      disabled: defectCreatorRef.current?.mode === 'create-defect',
      onDisabledMouseDown: onDisabledDrawingMouseDown,
      onAnnotationCreated,
      mousePos,
      lineStrokeWidth,
      segmentationOpacity,
      onInteractionEvent,
      onError: onCreatorError,
      wipText: text,
      otherTextSelected:
        annotations.findIndex(a => a.selected && a.type === CanvasAnnotationType.Text) > -1,
      fontSize: shapeProps.fontSize,
      imageDimensions: image ? { width: actualImageWidth, height: actualImageHeight } : undefined,
      defectCreatorRef,
      enableContour,
      imageSize: { width: actualImageWidth, height: actualImageHeight },
      onnxModel,
      imageEmbedding,
    });

    const cursor = useMemo(() => {
      if (customCursor) {
        return customCursor;
      } else if (draggable || isHoverOnAnnotation) {
        return 'grab';
      } else if (
        (defectCreatorRef.current?.mode === 'create-defect' || !finalColor) &&
        mode !== CanvasMode.IQuickLabeling &&
        (mode !== CanvasMode.View || !enableCrosshair)
      ) {
        return 'not-allowed';
      } else if (
        mode === CanvasMode.ILine ||
        mode === CanvasMode.IBox ||
        mode === CanvasMode.IPolyline ||
        (mode === CanvasMode.View && enableCrosshair)
      ) {
        return 'crosshair';
      } else if (mode === CanvasMode.IPolygon) {
        return finalColor === 'transparent' ? CursorPolygonMinus : CursorPolygonPlus;
      }
      return 'default';
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      draggable,
      isHoverOnAnnotation,
      finalColor,
      mode,
      defectCreatorRef.current?.mode,
      customCursor,
    ]);

    /**
     * Deal with `creatorCustomHandlers`
     */
    useClickOutside(mediaContainerRef, () => creatorCustomHandlers?.onOutsideClick?.());
    // `useKeyPress('esc', xxx)` has lower priority than MediaGrid.tsx, need to listen to '*' for workaround
    useKeyPress('*', e => {
      if (e.key === 'Escape') {
        creatorCustomHandlers?.onEscPressed?.();
      }
    });
    useKeyPress('enter', () => creatorCustomHandlers?.onEnterPressed?.());
    useEffect(() => {
      creatorCustomHandlers?.onModeChanged?.();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    const getOpacity = useCallback((opacity: number = 0.5, hovered?: boolean) => {
      if (hovered) {
        return opacity < 0.8 ? 0.8 : 1;
      }
      return opacity;
    }, []);

    const getSegmentationAnnotationsComponent = useCallback(
      (annotations: CanvasAnnotation[], options?: { enableContour?: boolean }) => {
        return annotations
          ?.filter(ann =>
            [
              CanvasAnnotationType.BitMap,
              CanvasAnnotationType.Line,
              CanvasAnnotationType.Polygon,
              CanvasAnnotationType.PureCanvas,
            ].includes(ann.type),
          )
          ?.map((annotationData, index) => {
            if (annotationData.type === CanvasAnnotationType.PureCanvas) {
              const pureCanvasAnnotation = annotationData.data as PureCanvasAnnotation;
              return (
                <PureCanvasAnnotationComponent
                  key={index}
                  x={pureCanvasAnnotation.x}
                  y={pureCanvasAnnotation.y}
                  canvas={pureCanvasAnnotation.canvas}
                  opacity={getOpacity(pureCanvasAnnotation.opacity, annotationData.hovered)}
                  width={pureCanvasAnnotation?.width}
                  height={pureCanvasAnnotation?.height}
                  enableContour={annotationData.enableContour ?? options?.enableContour}
                />
              );
            }
            if (annotationData.type === CanvasAnnotationType.BitMap) {
              const bitMapAnnotation = annotationData.data as BitMapAnnotation;
              return (
                <BitMapAnnotationComponent
                  key={bitMapAnnotation.key}
                  enableContour={annotationData.enableContour ?? options?.enableContour}
                  rangeBox={bitMapAnnotation.rangeBox}
                  bitMap={bitMapAnnotation.bitMap}
                  opacity={getOpacity(bitMapAnnotation.opacity, annotationData.hovered)}
                  color={bitMapAnnotation.color}
                />
              );
            }
            return (
              <LineAnnotationComponent
                key={index}
                annotation={annotationData.data as LineAnnotation}
                isPolygon={annotationData.type === CanvasAnnotationType.Polygon}
                opacity={getOpacity(
                  (annotationData.data as LineAnnotation).opacity,
                  annotationData.hovered,
                )}
                sync={
                  mode === CanvasMode.View ||
                  (zoomScale !== 'fit' && zoomScale > AccurateLabelingZoomScaleThreshold)
                }
                enablePixelation={enablePixelation}
                enableContour={annotationData.enableContour ?? options?.enableContour}
              />
            );
          });
      },
      [enablePixelation, getOpacity, mode, zoomScale],
    );

    const onUndo = useCallback(
      (e?: KeyboardEvent) => {
        e?.stopPropagation();
        e?.preventDefault();
        if (creatorCustomHandlers?.isWorkInProgress?.()) {
          creatorCustomHandlers?.onUndo?.();
          return;
        }
        if (!canUndo) {
          onInteractionEvent?.(CanvasInteractionEvent.NoMoreUndo);
          return;
        }
        const newAnnotations = undo();
        onAnnotationChanged?.(newAnnotations, AnnotationChangeType.Undo, layerRef.current);
      },
      [canUndo, creatorCustomHandlers, onAnnotationChanged, onInteractionEvent, undo],
    );

    useKeyPress(isMacOS() ? 'command+z' : 'ctrl+z', disableUndoRedo ? undefined : onUndo, {
      id: 'interactive-canvas-undo',
    });

    const onRedo = useCallback(() => {
      if (creatorCustomHandlers?.isWorkInProgress?.()) {
        creatorCustomHandlers?.onRedo?.();
        return;
      }
      if (!canRedo) {
        onInteractionEvent?.(CanvasInteractionEvent.NoMoreRedo);
        return;
      }
      const newAnnotations = redo();
      onAnnotationChanged?.(newAnnotations, AnnotationChangeType.Redo, layerRef.current);
    }, [canRedo, creatorCustomHandlers, onAnnotationChanged, onInteractionEvent, redo]);

    useKeyPress(
      isMacOS() ? 'command+shift+z' : 'ctrl+shift+z',
      disableUndoRedo ? undefined : onRedo,
      {
        id: 'interactive-canvas-redo',
      },
    );

    const setZoomScaleWithRePosition = useCallback(
      (newZoomScale: ZoomScale, mousePosition?: Position | null, skipCallback?: boolean) => {
        if (newZoomScale === 'fit') {
          fitStageToCenter(fitZoomScale);
        } else {
          updateStagePositionAfterZoom(newZoomScale, mousePosition);
        }
        setZoomScale(newZoomScale);
        stageRef.current?.getStage().batchDraw();
        !skipCallback && onZoomScaleChange?.(newZoomScale, mousePosition);
      },
      [fitStageToCenter, fitZoomScale, onZoomScaleChange, updateStagePositionAfterZoom],
    );

    const onMouseMove = useThrottle((e?: KonvaEventObject<MouseEvent>, skipCallback?: boolean) => {
      if (e && (isInteractive || enableCrosshair)) {
        const newPos = calcAccurateOffset(e, 'pixel-center');
        if (!isEqual(newPos, mousePos)) {
          setMousePos(newPos);
          if (!skipCallback) {
            const imageProps = {
              height: actualImageHeight,
              width: actualImageWidth,
            };
            onMouseMoveProps?.(e, {
              labelColor: extractColorFromHiddenLayer(
                layerRef.current?.getCanvas()._canvas,
                newPos,
                imageProps,
              ),
              predictColor: extractColorFromHiddenLayer(
                predictionLayerRef.current?.getCanvas()._canvas,
                newPos,
                imageProps,
              ),
              position: newPos,
            });
          }
        }

        defectCreatorHooks.onMouseMove(e);
        creatorInteractiveProps?.onMouseMove?.(e);
      } else {
        setMousePos(undefined);
      }
    }, 32);

    useImperativeHandle(
      ref,
      () => ({
        undo: onUndo,
        redo: onRedo,
        setZoomScale: setZoomScaleWithRePosition,
        setStagePosition: (p: Position) => {
          const stage = stageRef.current?.getStage();
          stage?.setPosition(p);
          stage?.batchDraw();
        },
        setAnnotations: (value, changeType, skipCallback = false) => {
          if (creatorCustomHandlers?.isWorkInProgress?.()) {
            return;
          }
          const newAnnotations = typeof value === 'function' ? value(annotations) : value;
          if (!isEmpty(newAnnotations) && isEqual(annotations, newAnnotations)) {
            return;
          }
          if (changeType === AnnotationChangeType.DeleteAll) {
            resetAnnotations([]);
            !skipCallback && onAnnotationChanged?.([], changeType, layerRef.current);
          } else if (changeType === AnnotationChangeType.DeleteGroundTruth) {
            const newAnnotations = annotations.filter(
              a => a.group === AnnotationSourceType.Prediction,
            );
            resetAnnotations(newAnnotations);
            !skipCallback && onAnnotationChanged?.(newAnnotations, changeType, layerRef.current);
          } else if (changeType === AnnotationChangeType.Reset) {
            resetAnnotations(newAnnotations);
            !skipCallback && onAnnotationChanged?.(newAnnotations, changeType, layerRef.current);
          } else {
            setAnnotations(newAnnotations);
            !skipCallback && onAnnotationChanged?.(newAnnotations, changeType, layerRef.current);
          }
        },
        setSelectedAnnotation,
        setHoveredAnnotation: ({ annotationId, color, group } = {}) => {
          const isHovered = (ann: CanvasAnnotation) => {
            const isPredictionFromAnn = ann.group === group;
            if (annotationId) {
              return typeof annotationId === 'string'
                ? ann.id === annotationId
                : annotationId.includes(ann.id) && isPredictionFromAnn;
            }

            return ann.data.color === color && isPredictionFromAnn;
          };

          replaceAnnotations(prev => {
            const newAnnotations = prev.map(ann => {
              return { ...ann, hovered: isHovered(ann) } as CanvasAnnotation;
            });
            return newAnnotations;
          });
        },
        resizeScale,
        mouseMove: onMouseMove,
        changeColor: (from, to) => {
          replaceAnnotations(prev => {
            return prev.map(
              ann =>
                ({
                  ...ann,
                  data: {
                    ...ann.data,
                    color: ann.data.color?.slice(0, 7) === from ? to : ann.data.color,
                  },
                } as CanvasAnnotation),
            );
          });
        },
        defectCreator: defectCreatorRef.current,
        pressEnter: newClassColor => creatorCustomHandlers?.onEnterPressed?.(newClassColor),
        clearWipAnnotations,
      }),
      [
        onUndo,
        onRedo,
        setZoomScaleWithRePosition,
        setSelectedAnnotation,
        resizeScale,
        onMouseMove,
        defectCreatorRef,
        clearWipAnnotations,
        creatorCustomHandlers,
        annotations,
        resetAnnotations,
        onAnnotationChanged,
        setAnnotations,
        replaceAnnotations,
      ],
    );

    useEffect(() => {
      if (mode === CanvasMode.View) {
        replaceAnnotations(annotationsProps);
      }
    }, [annotationsProps, mode, replaceAnnotations]);

    const prevEnableGrayscale = useRef<boolean>();

    const constructAnnotationLayer = useCallback(
      (
        annotations: CanvasAnnotation[],
        options?: { enableContour?: boolean; createdOnly?: boolean },
      ) => (
        <Layer
          imageSmoothingEnabled={false}
          // clip to avoid drawing outside the image
          clipX={0}
          clipY={0}
          clipWidth={actualImageWidth}
          clipHeight={actualImageHeight}
        >
          {annotations.map(ann => {
            if (options?.createdOnly && !ann.created) {
              return null;
            }
            switch (ann.type) {
              case CanvasAnnotationType.Box:
                return ann.group === AnnotationSourceType.Suggestion ? (
                  <BoxSuggestionAnnotationComponent
                    key={ann.id}
                    annotationId={ann.id}
                    annotation={ann.data as BoxAnnotation}
                    scale={zoomScaleNumber}
                    onSelect={setSelectedSuggestionAnnotation}
                    hovered={ann.hovered}
                  />
                ) : (
                  <BoxAnnotationComponent
                    key={ann.id}
                    annotationId={ann.id}
                    annotation={ann.data as BoxAnnotation}
                    editable={mode === CanvasMode.IBox && !spaceKey}
                    selected={ann.selected || ann.hovered}
                    scale={zoomScaleNumber}
                    onSelect={setSelectedAnnotation}
                    onChange={updateAnnotation}
                    onHoverChange={setIsHoverOnAnnotation}
                    mode={mode}
                    hovered={ann.hovered}
                    showTextOnHover={mode === CanvasMode.View}
                    defectCreatorRef={defectCreatorRef}
                  />
                );
              case CanvasAnnotationType.Text:
                return (
                  <TextAnnotationComponent
                    key={ann.id}
                    annotationId={ann.id}
                    annotation={ann.data as TextAnnotation}
                    fontSize={(ann.data as TextAnnotation).fontSize}
                    onSelect={setSelectedAnnotation}
                    selected={ann.selected}
                    onChange={updateAnnotation}
                    draggable={mode === CanvasMode.IText}
                  />
                );
              default:
                return getSegmentationAnnotationsComponent([ann], {
                  enableContour: options?.enableContour,
                });
            }
          })}
        </Layer>
      ),
      [
        actualImageHeight,
        actualImageWidth,
        defectCreatorRef,
        getSegmentationAnnotationsComponent,
        mode,
        setSelectedAnnotation,
        setSelectedSuggestionAnnotation,
        spaceKey,
        updateAnnotation,
        zoomScaleNumber,
      ],
    );

    return (
      <div
        className={classNames(styles.mediaCanvasContainer, className)}
        ref={mediaContainerRef}
        // if provided aspectRadio, use it to render the container, else render container at 100%
        style={aspectRadio ? { paddingBottom: `${100 / aspectRadio}%` } : { height: '100%' }}
      >
        {image ? (
          <>
            <Stage
              // Unfortunately aria-label and data-testid does not apply to dom element
              className="cy-media-interactive-canvas"
              ref={stageRef}
              onContextMenu={e => {
                e.evt.preventDefault();
                e.evt.stopPropagation();
              }}
              width={mediaContainerSize?.width}
              height={mediaContainerSize?.height}
              style={{ cursor }}
              scaleX={zoomScaleNumber}
              scaleY={zoomScaleNumber}
              draggable={draggable}
              dragBoundFunc={pos => {
                return freeDrag
                  ? pos
                  : calculateDraggableBoundary(
                      mediaContainerRef.current!,
                      actualImageWidth,
                      actualImageHeight,
                      zoomScaleNumber,
                      pos,
                    );
              }}
              onDragMove={e => {
                onDragMove?.(e.target.getPosition());
              }}
              {...(isInteractive ? creatorInteractiveProps : {})}
              onMouseMove={onMouseMove}
              onClick={e => {
                !isInteractiveMode &&
                  onInteractionEvent?.(CanvasInteractionEvent.NonInteractiveClick);
                isInteractive && creatorInteractiveProps?.onClick?.(e);
                onMouseClickProps?.(e, {
                  position: calcAccurateOffset(e, 'pixel-center'),
                });
              }}
              onPointerDown={(e: KonvaEventObject<PointerEvent>) => {
                if (e.evt.pointerId) {
                  // by setPointerCapture, mousemove event will be sent to canvas even when
                  // the pointer moves out of the canvas.
                  (e.evt.target as HTMLCanvasElement).setPointerCapture(e.evt.pointerId);
                }
              }}
              onPointerUp={(e: KonvaEventObject<PointerEvent>) => {
                if (e.evt.pointerId) {
                  (e.evt.target as HTMLCanvasElement).releasePointerCapture(e.evt.pointerId);
                }
              }}
              onMouseEnter={() => {
                // make sure it sets states after throttled move mouse event
                setTimeout(() => {
                  if (!isViewMode) {
                    defectCreatorHooks.onMouseEnter();
                  }
                }, 32 + 1);
              }}
              onMouseLeave={e => {
                // make sure it sets states after throttled move mouse event
                setTimeout(() => {
                  creatorInteractiveProps?.onMouseLeave?.(e);
                  setMousePos(undefined);
                  onMouseMoveProps?.(undefined, {});

                  if (!isViewMode) {
                    defectCreatorHooks.onMouseLeave();
                  }
                }, 32 + 1);
              }}
              onMouseUp={e => {
                // polygon needs mouse up event even when not interactive (i.e. panning) to save drawing curve
                if (mode === CanvasMode.IPolygon || isInteractive) {
                  creatorInteractiveProps?.onMouseUp?.(e);
                }
              }}
              onMouseDown={e => {
                if (annotations.some(ann => ann.selected)) {
                  setSelectedAnnotation('');
                }
                isInteractive && creatorInteractiveProps?.onMouseDown?.(e);
              }}
              onWheel={e => {
                if (!enablePinchScrollZoom) {
                  return;
                }

                e.evt.preventDefault();

                // for most browsers, trackpad pinch zoom sets wheelEvent.ctrlKey = true
                // references:
                //  * https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent
                //  * https://stackoverflow.com/questions/15416851/catching-mac-trackpad-zoom
                const pinchToZoom = e.evt.ctrlKey;
                const deltaRatio = pinchToZoom ? 4 : 1;
                const deltaY = -e.evt.deltaY * deltaRatio;

                // zoom speed should be corespondent to scroll speed (deltaY)
                const absDeltaY = Math.abs(
                  cap(deltaY, {
                    min: -ScrollDeltaYCap,
                    max: ScrollDeltaYCap,
                  }) / ScrollDeltaYCap,
                );
                const zoomRatio =
                  MinScrollZoomRatio + absDeltaY * (MaxScrollZoomRatio - MinScrollZoomRatio);

                // calculate new zoom scale
                const newZoomScale =
                  deltaY > 0 ? zoomScaleNumber * zoomRatio : zoomScaleNumber / zoomRatio;
                const { minZoomScale, maxZoomScale } = calcZoomScaleRange(fitZoomScale);
                const cappedNewZoomScale = cap(newZoomScale, {
                  min: minZoomScale,
                  max: maxZoomScale,
                });
                setZoomScaleWithRePosition(
                  cappedNewZoomScale,
                  e.target.getStage()?.getPointerPosition(),
                );
              }}
            >
              {/* image shapes, use given image as background */}
              <Layer imageSmoothingEnabled={false}>
                <Image
                  ref={ref => {
                    if (ref) {
                      imageRef.current = ref;

                      ref.getCanvas()._canvas.style.imageRendering = 'auto';
                      if (prevEnableGrayscale.current !== enableGrayscaleImage) {
                        ref.cache();
                        ref.filters(enableGrayscaleImage ? [Konva.Filters.Grayscale] : []);
                        prevEnableGrayscale.current = enableGrayscaleImage;
                      }
                    }
                  }}
                  image={image}
                  width={actualImageWidth}
                  height={actualImageHeight}
                />
              </Layer>
              {/* Prediction Layer - put above so label layer is on top in canvas */}
              {!hidePredictions && constructAnnotationLayer(displayAnnotationsWithPredictions)}
              {/* Label Layer */}
              {!hideLabels &&
                showLabelsProps &&
                constructAnnotationLayer(displayAnnotationsWithGroundTruthAndHeatmap, {
                  createdOnly: showLabelsProps === 'created-only',
                  enableContour,
                })}
              {/* Suggestion Layer */}
              {!hideLabels &&
                showLabelsProps &&
                constructAnnotationLayer(displayAnnotationsWithSuggestion)}

              <Layer
                imageSmoothingEnabled={false}
                // clip to avoid drawing outside the image
                clipX={0}
                clipY={0}
                clipWidth={actualImageWidth}
                clipHeight={actualImageHeight}
              >
                {(mousePos || mode === CanvasMode.IQuickLabeling) && creatorPreviewComponent}
                {enableCrosshair && <Crosshair mousePos={mousePos} scale={zoomScaleNumber} />}
              </Layer>
              <Layer>{creatorHelperComponent}</Layer>
            </Stage>
            {/* This is a hidden Stage/Layer with all the segmentation annotations, ref forwarded as the  */}
            {/* We can't use the display Stage above because it could be zoomed/dragged and hence the canvas might
            be cropped */}
            <Stage style={{ display: 'none' }} width={actualImageWidth} height={actualImageHeight}>
              <Layer ref={layerRef} imageSmoothingEnabled={false}>
                {getSegmentationAnnotationsComponent(displayAnnotationsWithGroundTruthAndHeatmap)}
              </Layer>
            </Stage>
            {/* This is a hidden Stage/Layer with all the prediction segmentation annotations, ref forwarded as the  */}
            {/* We can't use the display Stage above because it could be zoomed/dragged and hence the canvas might
            be cropped */}
            <Stage style={{ display: 'none' }} width={actualImageWidth} height={actualImageHeight}>
              <Layer ref={predictionLayerRef} imageSmoothingEnabled={false}>
                {getSegmentationAnnotationsComponent(displayAnnotationsWithPredictions)}
              </Layer>
            </Stage>
            {builtInZoom && (
              <ZoomFloater
                zoomScale={zoomScale}
                setZoomScale={setZoomScaleWithRePosition}
                fitZoomScale={fitZoomScale}
                zoomOption={builtInZoom}
              />
            )}
            {enableHideLabels && (
              <HideLabelsButtonFloater
                onHideLabelStatusChange={hideLabels => setHideLabels(hideLabels)}
              />
            )}
            {mousePos && (
              <div className={styles.mousePosition}>{`x ${mousePos.x}, y ${mousePos.y}`}</div>
            )}

            {enableDefectCreator && (
              <DefectCreator
                ref={loadDefectCreatorRef}
                color={pendingDefectColor}
                defectName={pendingDefectName}
                onCreateDefectConfirm={onDefectCreate}
                onEscapePressed={() => {
                  // Under create defect mode, pressing escape button should have the same effect as clicking mouse
                  // when drawing is disabled
                  if (defectCreatorRef.current?.mode === 'create-defect') {
                    onDisabledDrawingMouseDown?.();
                  }
                }}
                onClickAway={
                  enableDefectCreatorClickAwayEvent
                    ? () => {
                        if (defectCreatorRef.current?.mode === 'create-defect') {
                          onDisabledDrawingMouseDown?.();
                        }
                      }
                    : undefined
                }
                creatorTips={defectCreatorTips}
              />
            )}
          </>
        ) : (
          <Skeleton height={'100%'} width={'100%'} variant="rect" />
        )}
      </div>
    );
  },
);

MediaInteractiveCanvas.displayName = 'MediaInteractiveCanvas';

export default MediaInteractiveCanvas;
