import TiffToPngListWorker from './tiffToPngList.worker';
import type { FileWithPath } from 'react-dropzone';
import { dirname } from 'path';

export type TiffToPngListConverter = (tiffFile: FileWithPath) => Promise<FileWithPath[]>;

/**
 * Convert TIFF to PNG list async using WebWorker
 */
export const tiffToPngListAsync: TiffToPngListConverter = async tiffFile => {
  return new Promise((resolve, reject) => {
    const worker = new TiffToPngListWorker();
    worker.postMessage({ tiffFile });
    const handleMessage = (e: MessageEvent) => {
      const { pngFiles } = e.data as { pngFiles: File[]; messageId: number };
      // The `path` attribute will be lost when passed between main thread & worker.
      // We have to add it here instead of adding inside the worker.
      const folderName = dirname(tiffFile.path ?? '');
      if (folderName) {
        const pngFilesWithPath: FileWithPath[] = pngFiles.map(pngFile =>
          Object.assign(pngFile, { path: `${folderName}/${pngFile.name}` }),
        );
        resolve(pngFilesWithPath);
      } else {
        resolve(pngFiles);
      }
      worker.terminate();
    };
    worker.addEventListener('message', handleMessage);
    worker.addEventListener('error', (e: any) => reject(e));
  });
};
