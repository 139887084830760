/**
 * This file is shared by linePixelation.worker.ts, please keep this file as small as possible
 * and avoid importing large dependencies, so that web worker bundle will not go too large.
 */
import { Position, RangeBox } from '@clef/shared/types';
import Konva from 'konva';
import Bowser from 'bowser';
import { Tensor } from 'onnxruntime-web';
import { createPixelatedCanvas } from '../../utils/canvas';
import { hexToRgb } from '../../utils/color';

// used when clicking +/- zoom button, or pressing hot keys to zoom in / out
export const StepZoomRatio = 1.5;
// used when pinch / scroll to zoom in / out
export const ScrollDeltaYCap = 300;
export const MinScrollZoomRatio = 1;
export const MaxScrollZoomRatio = 2;

export const FitMediaPaddingTop = 100;
export const InstantLearningFitMediaPaddingTop = 20;
export const FitMediaPaddingBottom = 20;

export const AccurateLabelingZoomScaleThreshold = 10;

export type ZoomScale = 'fit' | number;
export type PositionType = 'pixel-corner' | 'pixel-center';

const { browser } = Bowser.parse(navigator.userAgent);
const isFirefox = browser.name === 'Firefox';

export const calcAccurateOffset = (
  e: Konva.KonvaEventObject<MouseEvent>,
  positionType: PositionType = 'pixel-corner',
): Position => {
  const { currentTarget, evt } = e;
  const roundFunction =
    positionType === 'pixel-corner' ? Math.round : (num: number) => Math.floor(num) + 0.5;

  // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/layerX
  // layerX and layerY are non-standard yet.
  const actualOffsetX = isFirefox ? (evt as any).layerX ?? evt.offsetX : evt.offsetX;
  const actualOffsetY = isFirefox ? (evt as any).layerY ?? evt.offsetX : evt.offsetY;
  return {
    x: roundFunction((actualOffsetX - (currentTarget.attrs.x ?? 0)) / currentTarget.attrs.scaleX),
    y: roundFunction((actualOffsetY - (currentTarget.attrs.y ?? 0)) / currentTarget.attrs.scaleY),
  };
};

/**
 * return Euclidean distance between two points
 */
export function distance(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
}

// Calculating point to line distance - https://gist.github.com/mattdesl/47412d930dcd8cd765c871a65532ffac
function distanceToLine(point: Position, line: { sx: number; sy: number; ex: number; ey: number }) {
  const dx = line.ex - line.sx;
  const dy = line.ey - line.sy;
  const l2 = dx * dx + dy * dy;

  if (l2 == 0) return distance(point.x, point.y, line.sx, line.sy);

  let t = ((point.x - line.sx) * dx + (point.y - line.sy) * dy) / l2;
  t = Math.max(0, Math.min(1, t));

  return distance(point.x, point.y, line.sx + t * dx, line.sy + t * dy);
}

/**
 * when line, pixel has to be within `strokeWidth` of the joint line of 2 adjacent points
 */
const pixelBelongToLine =
  (pts: number[], strokeWidth: number) =>
  (pixel: [number, number]): boolean => {
    return pts.some((thisPt, index) => {
      if (index === pts.length - 3 || index % 2 !== 0) {
        return false;
      }
      const curPt = [thisPt, pts[index + 1]];
      const nextPt = [pts[index + 2], pts[index + 3]];
      const distance = distanceToLine(
        { x: pixel[0], y: pixel[1] },
        { sx: curPt[0], sy: curPt[1], ex: nextPt[0], ey: nextPt[1] },
      );
      return distance < strokeWidth;
    });
  };
/**
 * when polygon, pixel has to be within the closed shape points created
 * https://github.com/substack/point-in-polygon
 */
const pixelBelongInsidePolygon =
  (pts: number[]) =>
  (pixel: [number, number]): boolean => {
    let [x, y] = pixel;
    // we should check if the center point of a pixel locates in the polygon, not the top-left point.
    x += 0.5;
    y += 0.5;

    let inside = false;
    for (let i = 0, j = pts.length - 2; i < pts.length; j = i, i += 2) {
      const xi = pts[i];
      const yi = pts[i + 1];
      const xj = pts[j];
      const yj = pts[j + 1];

      const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };

export const getRangeBoxFromLinePoints = (
  points: number[],
  strokeWidth: number,
  isPolygon?: boolean,
) => {
  const padding = isPolygon ? 1 : strokeWidth;
  const rangeBox = points.reduce((acc, x, index) => {
    if (index % 2 !== 0) return acc;
    const y = points[index + 1];
    return {
      xmin: Math.min(x - padding, acc?.xmin ?? Infinity),
      xmax: Math.max(x + padding, acc?.xmax ?? 0),
      ymin: Math.min(y - padding, acc?.ymin ?? Infinity),
      ymax: Math.max(y + padding, acc?.ymax ?? 0),
    };
  }, {} as RangeBox);
  return rangeBox;
};
/**
 * Convert line points coordinates to pixelated bitmap canvas and range box.
 * @param points the coordinates array in format of [x, y]
 * @param strokeWidth the line width, only useful when isPolygon=false
 * @param color the color to draw canvas
 * @param isPolygon when shape is polygon, are is filled within the closure shape instead of drawn with strokeWidth
 * @returns
 */
export const linePointsToPixelatedCanvas = (
  points: number[],
  strokeWidth: number,
  color: string,
  isPolygon?: boolean,
): [OffscreenCanvas, RangeBox] => {
  const padding = isPolygon ? 1 : strokeWidth;

  const rangeBox = getRangeBoxFromLinePoints(points, strokeWidth, isPolygon);
  const { xmin, xmax, ymin, ymax } = rangeBox;
  const width = xmax - xmin + 1;
  const height = ymax - ymin + 1;

  const pixelBelongFunc = isPolygon
    ? pixelBelongInsidePolygon(points)
    : pixelBelongToLine(points, padding);

  const offscreen = createPixelatedCanvas(
    { width, height },
    ({ x, y }) => pixelBelongFunc([x + xmin, y + ymin]),
    color,
  );

  return [offscreen, rangeBox];
};

/**
 * Zoom scale range based on fitZoomScale.
 */
export function calcZoomScaleRange(fitZoomScale: number) {
  return {
    minZoomScale: Math.min(1, fitZoomScale * 0.5),
    maxZoomScale: Math.max(1, fitZoomScale * StepZoomRatio ** 8),
  };
}

export function getDisplayZoomValue(zoomScale: number) {
  if (zoomScale >= 0.1) {
    return Math.round(zoomScale * 100) + '%';
  } else {
    return Math.round(zoomScale * 10000) / 100 + '%';
  }
}

export function extractColorFromHiddenLayer(
  canvas: HTMLCanvasElement | undefined,
  position: Position,
  imageProps: { height: number; width: number },
) {
  if (!canvas) return undefined;
  // get pixel color
  const scaleX = canvas.width / imageProps.width;
  const scaleY = canvas.height / imageProps.height;
  const context = canvas?.getContext('2d', { willReadFrequently: true });
  const data = context?.getImageData(position.x * scaleX, position.y * scaleY, 1, 1).data;
  const [r, g, b, alpha] = data ?? [];

  return alpha > 0 ? { r, g, b } : undefined;
}

/**
 * get new position for zooming relative to zoomCenter.
 * Reference: https://konvajs.org/docs/sandbox/Zooming_Relative_To_Pointer.html
 */
export const getStagePositionAfterZoom = (
  oldPosition: Position,
  zoomCenter: Position,
  oldZoomScale: number,
  newZoomScale: number,
) => {
  const { x: stageX, y: stageY } = oldPosition;
  const { x: zoomCenterX, y: zoomCenterY } = zoomCenter;
  const dX = (zoomCenterX - stageX) / oldZoomScale;
  const dY = (zoomCenterY - stageY) / oldZoomScale;
  return {
    x: zoomCenterX - dX * newZoomScale,
    y: zoomCenterY - dY * newZoomScale,
  };
};

// zoomed image can't drag over media container edges, this function min/max out when zoomed is dragged or moved over
// the edge
export const calculateDraggableBoundary = (
  mediaContainer: HTMLDivElement,
  imageWidth: number,
  imageHeight: number,
  zoomedScale: number,
  pos: Position,
): Position => {
  const maxXMargin = (mediaContainer.clientWidth - imageWidth * zoomedScale) / 2;
  const maxYMargin = (mediaContainer.clientHeight - imageHeight * zoomedScale) / 2;
  const x = maxXMargin >= 0 ? maxXMargin : Math.min(Math.max(pos.x, 2 * maxXMargin), 0);
  const y = maxYMargin >= 0 ? maxYMargin : Math.min(Math.max(pos.y, 2 * maxYMargin), 0);
  return { x, y };
};

// Reference: https://github.com/facebookresearch/segment-anything/blob/main/demo/src/components/helpers/maskUtils.tsx
export const arrayToImageData = ({
  array,
  width,
  height,
  color,
  threshold = 0.0,
}: {
  array: number[];
  width: number;
  height: number;
  color: {
    r: number;
    g: number;
    b: number;
    a?: number;
  };
  threshold?: number;
}) => {
  if (width * height !== array.length) {
    throw new Error(
      `Array length (${array.length}) does not match width * height (${width * height})`,
    );
  }

  const { r, g, b, a = 255 } = color;
  const arr = new Uint8ClampedArray(4 * width * height).fill(0);
  for (let i = 0; i < array.length; i++) {
    if (array[i] > threshold) {
      arr[4 * i + 0] = r;
      arr[4 * i + 1] = g;
      arr[4 * i + 2] = b;
      arr[4 * i + 3] = a;
    }
  }
  return new ImageData(arr, width, height);
};

// Reference: https://github.com/facebookresearch/segment-anything/blob/main/demo/src/components/helpers/maskUtils.tsx
export const imageDataToCanvas = (imageData: ImageData) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d', {
    desynchronized: true,
  });
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  ctx?.putImageData(imageData, 0, 0);
  return canvas;
};

// Reference: https://github.com/facebookresearch/segment-anything/blob/main/demo/src/components/helpers/maskUtils.tsx
export const imageDataToImage = (imageData: ImageData) => {
  const canvas = imageDataToCanvas(imageData);
  const image = new Image();
  image.src = canvas.toDataURL();
  return image;
};

export const imageDataToOffscreenCanvas = (imageData: ImageData) => {
  const offscreenCanvas = new OffscreenCanvas(imageData.width, imageData.height);
  const context = offscreenCanvas.getContext('2d', {
    desynchronized: true,
  })!;
  context.imageSmoothingEnabled = false;
  context.putImageData(imageData, 0, 0);
  return offscreenCanvas;
};

export const offscreenCanvasWithNewColor = (canvas: OffscreenCanvas, newColor: string) => {
  const context = canvas.getContext('2d', { desynchronized: true })!;
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  const { r, g, b } = hexToRgb(newColor);

  for (let i = 0; i < imageData.data.length >> 2; i++) {
    const index = i << 2;

    // Only update color data if the alpha is above zero
    if (imageData.data[index + 3] > 0.0) {
      imageData.data[index] = r;
      imageData.data[index + 1] = g;
      imageData.data[index + 2] = b;
    }
  }

  return imageDataToOffscreenCanvas(imageData);
};

const convertIndexToCoord = (index: number, width: number) => {
  if (index >= 0 && width > 0) {
    const y = Math.trunc(index / width);
    const x = index % width;
    return { x, y };
  } else {
    throw Error(`Invalid index ${index} or width ${width}`);
  }
};

const convertCoordToIndex = (x: number, y: number, width: number) => {
  if (x >= 0 && y >= 0 && width > 0) {
    return x + y * width;
  } else {
    throw Error(`Invalid x ${x}, y ${y}, or width ${width}`);
  }
};

/**
 * Filter out small areas (connected pixel blocks) of the SAM mask
 * @param tensor The SAM result mask Tensor
 * @param threshold The threshold to filter out pixels
 * @param smallAreaThreshold The threshold to filter out small areas
 */
export const filterSmallArea = (
  tensor: Tensor,
  threshold = 0.0,
  smallAreaThreshold = 100,
): Tensor => {
  const visited = new Array(tensor.data.length).fill(false);
  // Mapping from pixel index to area id, where "0" stands for the pixel not belonging to any area
  const pixelIndexToAreaId = new Array(tensor.data.length).fill(0);
  // "-1" is a placeholder without any meaning, as area id is 1-based numbering
  const areaSize: number[] = [-1];

  const [, , height, width] = tensor.dims;
  let areaCount = 0;

  const dxs = [0, 0, 1, -1];
  const dys = [1, -1, 0, 0];

  const findArea = (pixelIndex: number) => {
    // Using BFS to avoid potential stack overflows
    const coords = [convertIndexToCoord(pixelIndex, width)];
    visited[pixelIndex] = true;

    while (coords.length) {
      const { x, y } = coords.shift()!;
      const index = convertCoordToIndex(x, y, width);

      // All traversed coordinates will belong to the same area
      pixelIndexToAreaId[index] = areaCount;
      areaSize[areaCount]++;

      for (let i = 0; i < 4; i++) {
        const xx = x + dxs[i];
        const yy = y + dys[i];
        if (xx < 0 || yy < 0) continue;
        const newIndex = convertCoordToIndex(xx, yy, width);

        if (
          // Boundary check
          0 <= xx &&
          xx < width &&
          0 <= yy &&
          yy < height &&
          // Duplication check
          !visited[newIndex]
        ) {
          // Directly mark the new pixel index as visited to avoid duplicate visits
          visited[newIndex] = true;

          // If the new coordinate is a valid prediction, append it to the queue
          if (Number(tensor.data[newIndex]) > threshold) {
            coords.push({
              x: xx,
              y: yy,
            });
          }
        }
      }
    }
  };

  for (let i = 0; i < tensor.data.length; i++) {
    if (!visited[i] && Number(tensor.data[i]) > threshold) {
      areaCount++;
      areaSize.push(0);

      findArea(i);
    }
  }

  return {
    ...tensor,
    data: (tensor.data as unknown as number[]).map((value, index) => {
      const areaId = pixelIndexToAreaId[index];

      // If the belonging area size is lower than the threshold, reset to 0; otherwise, keep the original value
      return areaSize[areaId] >= smallAreaThreshold ? value : 0;
    }) as unknown as Tensor['data'],
  };
};
